<template>
  <div class="readme-article">
    <h1 id="其他">
      三、其他
    </h1>
    <h2 id="修改日志">1.修改日志</h2>
    <p>查看修改日志列表，可查看日志详情。</p>
    <p>
      <img src="@/assets/img/staff/3-1.png">
    </p>
    <h2 id="操作日志">2.操作日志</h2>
    <p>查看操作日志列表。</p>
    <p>
      <!-- <img src="@/assets/img/staff/3-2.png"> -->
    </p>
    <h2 id="登录日志">3.登录日志</h2>
    <p>查看登录日志列表。</p>
    <p>
      <!-- <img src="@/assets/img/staff/3-3.png"> -->
    </p>
    <h2 id="清除日志">4.清除日志</h2>
    <p>管理员可清楚N天前的操作日志和登录日志。</p>
    <p>
      <img src="@/assets/img/staff/3-4.png">
    </p>
    <h2 id="考勤记录">5.考勤记录</h2>
    <p>查看考勤记录列表，包含员工姓名、考勤类型、天数等信息。</p>
    <p>
      <img src="@/assets/img/staff/3-5.png">
    </p>
    <h2 id="导入员工">6.导入员工</h2>
    <p>下载上传资料模板并按照模板的格式填写完整数据后，选择该文件一键导入员工。</p>
    <p>
      <img src="@/assets/img/staff/3-6.png">
    </p>
  </div>
</template>

<script>
export default {
  name: "Staff3-1",
  data() { 
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>